import React from "react";

const ShowSponsors = ({eventData}) => {
  // Initialize state to store event data
  if (!eventData || !eventData.sponsors || eventData.length === 0) {
    return <p className="text-[#2D1066] text-xl font-medium ">No Sponsors available </p>;
  }

 
  return (
    <div className="flex gap-2">
      {eventData.sponsors.map((sponsors) => (
        <div key={sponsors.SponsorId}>
          <div >{sponsors.SponsorName}</div>
        </div>
      ))}
    </div>
  );
};

export default ShowSponsors;
