import axios from 'axios';
import { useState, useEffect } from 'react';
import SquareBanner from "../../../Asset/net-gala-square-banner.svg"

const JoinMembershipModel = ({ onClose, toggleLoader }) => {
    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        email: '',
        mobile: '',
        agreeTerms: false,
        subscribeUpdates: false
    });

    const [errors, setErrors] = useState({}); 

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        setFormData({
            ...formData,
            [name]: type === 'checkbox' ? checked : value
        });
    };

    const validateFields = () => {
        const newErrors = {};
        if (!formData.firstName) newErrors.firstName = true;
        if (!formData.lastName) newErrors.lastName = true;
        if (!validateEmail(formData.email)) newErrors.email = true;
        if (!validateMobile(formData.mobile)) newErrors.mobile = true;
        if (!formData.agreeTerms) newErrors.agreeTerms = true;

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0; 
    };

    const validateEmail = (email) => {
        // Basic email regex for validation
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };

    const validateMobile = (mobile) => {
        // Validate that the mobile number is exactly 10 digits
        const mobileRegex = /^[0-9]{10}$/;
        return mobileRegex.test(mobile);
    };

    const registerClient = async (e) => {
        e.preventDefault();

        if (!validateFields()) return;

        try {
            toggleLoader();

            const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/prospect-users/`, {
                FirstName: formData.firstName,
                LastName: formData.lastName,
                MobileNumberExt: "+91",
                MobileNumber: formData.mobile,
                EmailAddress: formData.email,
              },
              {
                headers: {
                  "Content-Type": "application/json",
                  accept: "application/json",
                },
              });

            if (response.status === 201) {
                alert('Registration successful!');
                onClose();
            }
        } catch (error) {
            alert('Registration failed. Please try again.');
        } finally {
            toggleLoader();
        }
    };

    useEffect(() => {
        document.body.style.overflow = 'hidden';
        return ()=> document.body.style.overflow = 'unset';
     }, []);

    return (
        <div className="fixed inset-0 bg-black bg-opacity-60 flex justify-center items-center z-50">
            <div className="bg-[var(--white)] rounded-3xl shadow-lg w-full md:max-w-6xl max-h-[95vh] mx-4 flex flex-col md:flex-row">
                <button onClick={onClose} className="absolute top-2 right-2 text-lg text-gray-500 hover:text-gray-700">
                    &#x2715;
                </button>
                <div className="flex flex-col lg:flex-row items-center">
                    {/* Left Form Section */}
                    <div className="w-full lg:w-1/2 mb-6 lg:mb-0">
                        <h2 className="text-2xl font-semibold mb-4">Join Membership Waitlist</h2>
                        <form className="space-y-4" onSubmit={registerClient}>
                            <div className="flex flex-col">
                                <input
                                    type="text"
                                    name="firstName"
                                    value={formData.firstName}
                                    onChange={handleChange}
                                    placeholder="First Name *"
                                    className={`p-2 border ${errors.firstName ? 'border-red-500' : 'border-gray-300'} rounded`}
                                />
                            </div>
                            <div className="flex flex-col">
                                <input
                                    type="text"
                                    name="lastName"
                                    value={formData.lastName}
                                    onChange={handleChange}
                                    placeholder="Last Name *"
                                    className={`p-2 border ${errors.lastName ? 'border-red-500' : 'border-gray-300'} rounded`}
                                />
                            </div>
                            <div className="flex flex-col">
                                <input
                                    type="email"
                                    name="email"
                                    value={formData.email}
                                    onChange={handleChange}
                                    placeholder="Email Address *"
                                    className={`p-2 border ${errors.email ? 'border-red-500' : 'border-gray-300'} rounded`}
                                />
                            </div>
                            <div className="flex flex-col">
                                <input
                                    type="text"
                                    name="mobile"
                                    value={formData.mobile}
                                    onChange={handleChange}
                                    placeholder="Mobile Number *"
                                    className={`p-2 border ${errors.mobile ? 'border-red-500' : 'border-gray-300'} rounded`}
                                />
                            </div>
                            <div className="flex items-center">
                                <input type="checkbox" name="subscribeUpdates" checked={formData.subscribeUpdates} onChange={handleChange} className="mr-2" />
                                <label>Keep me updated on more events and news from Net Gala</label>
                            </div>
                            <div className="flex items-center">
                                <input type="checkbox" name="agreeTerms" checked={formData.agreeTerms} onChange={handleChange} className={`mr-2 ${errors.agreeTerms ? 'border-red-500' : ''}`} />
                                <label>
                                    I agree to the{' '}
                                    <a href="#" className="text-blue-500">
                                        Net Gala Terms of Service
                                    </a>
                                </label>
                            </div>
                            <button type="submit" className="w-full py-2 rounded-md">
                                Register
                            </button>
                        </form>
                    </div>

                    {/* Right Image Section */}
                    <div className="w-full lg:w-1/2 flex justify-center items-center">
                        <img src={SquareBanner} alt="Net Gala" className="w-half h-half object-cover rounded-lg" />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default JoinMembershipModel;
