import React from 'react';
import { Link, useParams } from 'react-router-dom';

const NavigationMobile = ({ isOpen, onClose }) => {
    let { eventId } = useParams();
  return (
    <div className={`fixed inset-0 z-50 ${isOpen ? 'block' : 'hidden'}`}>
      {/* Overlay with blur */}
      <div
        className="absolute inset-0 bg-gray-400 bg-opacity-50 backdrop-blur-lg"
        onClick={onClose}
      ></div>

      {/* Mobile Menu */}
      <div className="absolute top-0 right-0 w-4/5 h-full bg-transparent flex flex-col justify-center px-8">
        
        
        {/* Close Button */}
        <button
          className="absolute top-4 right-4 text-3xl font-bold text-white"
          onClick={onClose}
        >
          ×
        </button>

        {/* Navigation Links */}
        <nav className="flex flex-col space-y-10 text-left mt-10">
          <Link to="/events/0e26fbc7-be70-4a66-bebd-f3638e503df4" onClick={onClose}>
            <button className="text-3xl font-semibold text-white">Events</button>
          </Link>

          {/* <Link to="/memberships" onClick={onClose}>
            <button className="text-3xl font-semibold text-white">Memberships</button>
          </Link> */}

          <Link to="/about" onClick={onClose}>
            <button className="text-3xl font-semibold text-white">About</button>
          </Link>
        </nav>
      </div>
    </div>
  );
};

export default NavigationMobile;
