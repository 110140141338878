import React from "react";
import runningImage from "../../../Asset/Events/runningImageLong.svg";
import yogaImage from "../../../Asset/Events/yogaImageLong.svg";
import leagueBadge from "../../../Asset/Events/League-Badge/leagueBadge.svg";
import leagueBadgeDumbbell from "../../../Asset/Events/League-Badge/leagueBadgeDumbbell.svg";
import runningImageMobile from "../../../Asset/Events/runningImage-mobile.svg";
import calendarVector from "../../../Asset/Events/calendar-vector.svg";
import locationVector from "../../../Asset/Events/location-vector.svg";
import timeVector from "../../../Asset/Events/time-vector.svg";
import coinVector from "../../../Asset/Events/coin-vector.svg";

const MoreEventCard = ({ eventData }) => {
  // Ensure eventData exists and has MoreEvents array
  if (!eventData || !eventData.MoreEvents || eventData.MoreEvents.length === 0) {
    return;
  }

  return (
    <>
    <div className="moreEvents-container  flex flex-col  justify-center items-center pt-14 ">
    <h1 className='text-[var(--dark-blue)] text-3xl md:text-[2rem] font-medium  w-[100%] pl-12 mb-12 font-ClashGrotesk '>More Events</h1>
      {eventData.MoreEvents.map((moreevents) => (
        <div className="w-full" key={moreevents.moreEventId}>
          {/* Card 1 Container here */}
          <div className="card1 mb-12 flex flex-col md:flex-row justify-between items-center w-[100%] md:w-4/5 rounded-3xl  ml-12">
            {/* Inside container left part goes here */}
            <div className="flex flex-col md:flex-row md:w-[60%] md:h-[95%] items-center md:p-10 p-2 md:gap-4 ">
              {/* Image here */}
              <div className="md:hidden p-3">
                <img src={runningImageMobile} alt="Running "
                className="w-full" />
              </div>
              <div className="hidden md:block 2xl:w-[35%] 2xl:p-2">
                <img className="w-full" src={yogaImage} alt="Yoga " />
              </div>

              {/* Text part here */}
              <div className="flex flex-col justify-between md:w-full text-[var(--medium-purple)] ">
                <div className="flex ">
                  {/* Heading of the card */}
                  <div className="w-3/5 md:w-full">
                    <h1 className="xl:text-5xl md:text-3xl text-2xl font-semibold py-2">
                      {moreevents.EventTitle}
                    </h1>
                  </div>

                  {/* Badge image for Mobile view */}
                  <div className="md:hidden w-[45%] ml-0 ">
                    <img
                      className="more-events-leagueBadge-image -mt-16"
                      src={leagueBadge}
                      alt="League Badge Smiley"
                    />
                  </div>
                </div>

                <p className="pb-3">{moreevents.EventDescription}</p>
                <hr className="border border-[var(--medium-purple)] border-solid md:mb-5 mb-4" />

                {/* Inside partition */}
                <div className="flex pb-2 text-base w-full ">
                  <span className="flex gap-1 w-full">
                    <img src={calendarVector} alt="calendar vector" /> 28th July, 2024
                  </span>
                  <span className="flex gap-1 w-full">
                    <img src={timeVector} alt="clock vector" /> 6:30 AM - 9:30 AM
                  </span>
                </div>
                <div className="flex pb-2 ">
                  <span className="flex gap-1 w-full">
                    <img src={locationVector} alt="location vector" /> Aravalli Hills
                  </span>
                  <span className="flex gap-1 w-full">
                    <img src={coinVector} alt="coin vector" /> ₹1000
                  </span>
                </div>
              </div>
            </div>

            {/* Inside container right part goes here */}
            <div className="flex flex-col justify md:items-end items-center md:w-[30%] md:h-[90%] gap-2 p-4 md:mr-5">
              {/* Badge Image here */}
              <img
                className="more-events-leagueBadge-image hidden md:block"
                src={leagueBadgeDumbbell}
                alt="League Badge Smiley"
              />

              {/* Register Button here */}
              <button className="border border-solid border-[var(--medium-purple)] text-[var(--purple-blue)] text-lg md:px-32 px-28 py-3 rounded-xl md:mr-4 font-medium hover:bg-[var(--medium-purple)] hover:text-[var(--white)] hover:font-semibold">
                Register
              </button>
            </div>
          </div>
        </div>
      ))}
       </div>
    </>
  );
};

export default MoreEventCard;
