import React from "react";
import "./homeAboutUs.css";
import padelImg from "../../../Asset/home-about-us-event-1.svg"
import footballImg from "../../../Asset/home-about-us-event-2.svg"
import pickleballImg from "../../../Asset/home-about-us-event-3.svg"
import UpcomingEvents from "./UpcomingEvents";
import { Link } from 'react-router-dom';

const HomeAboutUs = () => {
  return (
    <div className="homepage-lineargradient-bg-color">
      <div className="home-about-us pattern-container z-20">
        <div className="home-about-us-content pt-[3.6rem] p-2 ">
          <div className="home-about-us-content-left text-4xl md:text-5xl lg:text-[3.5rem] font-ClashGrotesk font-[460]">
            <p>Make play part <br /> of your every day</p>
          </div>

          <div className="home-about-us-content-right lg:pl-4 pr-2 ">
            <p className="font-manrope text-[1.18rem] font-[450] pt-4 md:pt-0  ">
              We're Net Gala, India's first exclusive lifestyle community where sports aren't just a hobby – they're a way of life. We're all about helping you rediscover the joy of play and create a life outside the ordinary.
            </p>
            <div className=" w-full flex gap-4 2xl:gap-6 justify-center items-center pt-6 font-manrope text-base font-[700]">

              <Link to="/about" className="w-1/2">
                <button className="rounded-xl text-[var(--home-about-us-ptn-button-grey)] bg-[var(--button-green)] p-3 lg:py-4 w-full">
                  <strong>About Us</strong>
                </button>
              </Link>

              <button className="rounded-xl bg-[var(--homeAboutUs-purple-button)]  border border-solid border-[var(--dull-white-followUs-button-border)]  text-[var(--white)] p-3 lg:py-4 w-1/2">Follow Us </button>
            </div>
          </div>
        </div>

        <div className="home-about-us-cards flex flex-nowrap justify-between items-center w-full ">
          <div className="home-about-us-card w-[32%] ">
            <img className="w-full" src={padelImg} alt="padel game"></img>
          </div>
          <div className="home-about-us-card w-[32%] ">
            <img className="w-full" src={footballImg} alt="football game"></img>
          </div>
          <div className="home-about-us-card w-[32%] ">
            <img className="w-full" src={pickleballImg} alt="pickleball game"></img>
          </div>
        </div>
        <br /><br />
        <div className="home-about-us-footer font-ClashGrotesk text-3xl md:text-[3.7rem] font-[470]  ">
          <p>
            Get ready to be part of the most sought-after social  sports community
            ever assembled!
          </p>
        </div>

        <UpcomingEvents></UpcomingEvents>

      </div>
    </div>
  );
};

export default HomeAboutUs;