import React from 'react'
import './brandAndCommunity.css'
import img from "../../../Asset/demo-community-pic.svg"

const community = () => {
    return (
        <div class="community-main py-20 pattern-container">

            {/* Community content goes here */}
            <div className="community flex flex-col lg:flex-row justify-center items-center ">
                <div className="heading-community w-full md:w-1/2 p-10 flex">
                    <p class="text-white text-4xl md:text-[4rem] text-left leading-[4.3rem]  font-ClashGrotesk font-[480] h-[15vh] ">Hear from Our Community </p>
                </div>

               

                <div className="community-cards flex flex-col w-full px-2  2xl:w-1/2 justify-end mr-2 " >

                    {/* Community Card 1 */}
                    <div class="w-[full]   flex justify-end md:mr-8 -mb-4">

                        <div className=" flex flex-col md:flex-row community-comments-parent w-[73%] pl-8 py-6 pr-2 bg-white   justify-center items-center rounded-lg shadow-xl  border border-opacity-10 border-solid border-black z-30">
                            <div className="image-container  w-1/3 ">
                                <img className='w-full' src={img} alt="demo" />
                            </div>
                            <div className="community-comments flex flex-col text-left w-2/3  text-[#616161] font-ClashGrotesk pl-2">
                                <h1 class="text-xl font-semibold">Sanjana Tyagi</h1>
                                <h2 class="text-sm">Budding footballer</h2>
                                <p class="text-sm mt-2 font-manrope text-left w-full    ">Net Gala has become my go-to for weekend plans. It's the perfect way to kickstart the weekend with some friendly competition
                                    and new connections. If you're looking for something fun and different to do, this is it.</p>
                            </div>
                        </div>
                    </div>


                    {/* Community Card 2 */}
                    <div class="w-[full]  flex justify-start md:ml-6 ">

                        <div className="community-comments-parent w-[73%] p-2 md:p-6 pb-8 bg-white  flex  rounded-lg shadow-xl border border-opacity-10 border-solid border-black">

                            <div className="community-comments flex flex-col text-left font-ClashGrotesk  text-[#616161]">
                                <h1 class="text-xl font-semibold">Shayoni Nair</h1>
                                <h2 class="text-sm">Budding footballer</h2>
                                <p class="text-justify text-sm mt-2 font-manrope">Joining Net Gala was the best decision! I’ve met some really cool people who are just as into the game as I am. It’s not just about the sport—it's about building friendships with folks I actually want to hang out with.
                                </p>
                            </div>
                        </div>
                    </div>


                    {/* Community Card 3 */}
                    <div class="w-[full]   flex justify-end md:mr-8 z-30 -mt-2">

                        <div className="community-comments-parent w-[73%] p-2 md:p-6 bg-white  flex  rounded-lg shadow-xl border border-opacity-10 border-solid border-black">
                            
                            <div className="community-comments flex flex-col text-left font-ClashGrotesk  text-[#616161]">
                                <h1 class="text-xl font-semibold">Gyan Lakhwani</h1>
                                <h2 class="text-sm">Ultimate Frisbee Fanatic</h2>
                                <p class="text-justify text-sm mt-2 font-manrope">I used to play sports ages ago, but finding a group at my skill level felt impossible. It’s been amazing connecting with others who are also getting back into the game. Just a fun way to ease back into something I used to love.</p>
                            </div>
                        </div>
                    </div>


                </div>
            </div>

        </div>
    )
}

export default community
