import React from 'react';

const LeaveCheckout = ({ onStay, onLeave }) => {
  return (
    <div className="bg-[var(--background-light-gray)] p-6 md:p-8 rounded-lg shadow-xl w-full max-w-6xl  mx-2 sm:mx-auto flex flex-col items-center">
      {/* Title */}
      <h1 className="font-ClashGrotesk text-center text-xl md:text-3xl font-medium text-[var(--dark-blue)] mb-4">
        Leave Checkout?
      </h1>
      
      {/* Message */}
      <p className="text-center text-sm md:text-lg font-medium font-manrope text-[var(--dark-blue)] mb-6">
        Are you sure you want to leave checkout? The registration details that you’ve added will be lost.
      </p>
      
      {/* Buttons */}
      <div className="flex gap-4">
        {/* Stay Button */}
        <button 
          className="px-14 md:px-20 py-2 md:py-3 bg-[var(--white)] text-[var(--dark-blue)]  border border-[var(--dark-blue)] rounded-xl hover:bg-[var(--dull-white)]"
          onClick={onStay}
        >
          Stay
        </button>
        
        {/* Leave Button */}
        <button 
          className="px-14 md:px-20 py-2 md:py-3 bg-[var(--purple-blue)] text-[var(--white)] rounded-xl hover:bg-[var(--dark-blue)]"
          onClick={onLeave}
        >
          Leave
        </button>
      </div>
    </div>
  );
};

export default LeaveCheckout;
