import React from 'react'
import "./customerOptions.css";
import FindPlayCard from "../../../Asset/find-play.svg"
import FindCommunityCard from "../../../Asset/find-community.svg"
import FindCompetitionCard from "../../../Asset/find-competition.svg"

const customerOptions = () => {
    return (
        <div class="w-full  md:bg-[var(--light-yellow)] bg-[var(--very-light-yellow)] flex flex-col p-4 md:p-16 text-[var(--greenish-black)]">

            {/* Upper Container */}
            <div className="customerOptions-upper-container flex justify-between mb-10">
                <div className="two-columns md:w-1/2 text-left  text-4xl md:text-5xl lg:text-[3.5rem]  font-[490]">
                    <h1 className='font-ClashGrotesk '>What's in it <br />
                        for you</h1>
                </div>
                <div className="two-columns md:w-1/2 font-manrope text-base md:text-xl pl-4 font-[490] text-left">
                    <p>Imagine a weekend soirée, where you earn your cold ones by sweating it out first.  Prepare to rub shoulders with your future besties, unleash your competitive spirit, and forge bonds that could outlast the longest winning streak. Get ready to be part of the most sought-after sports social club ever assembled! </p>
                </div>
            </div>

            {/* Lower Container */}
            <div className="customerOptions-bottom-container w-full flex flex-nowrap items-center justify-between ">

                <div className="customerOptions-card find-play w-[32%]">
                    <img className="w-full" src={FindPlayCard} alt="Find Play Card" />
                </div>
                <div className="customerOptions-card find-community w-[32%]">
                    <img className="w-full" src={FindCommunityCard} alt="Find Community Card" />
                </div>
                <div className="customerOptions-card find-competition w-[32%]">
                    <img className="w-full" src={FindCompetitionCard} alt="Find Competition Card" />
                </div>

            </div>

        </div>
    )
}

export default customerOptions
