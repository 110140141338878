import React, { useRef } from 'react'
import footballImage from '../../../Asset/carousel-football-image.svg'
import leftArrowVector from "../../../Asset/left-arrow-vector-carousel.svg"
import rightArrowVector from "../../../Asset/right-arrow-vector-carousel.svg"

const StayInTouchCarousel = () => {

    const carouselRef = useRef(null);

    const moveCarousel = (direction) => {
        const carousel = carouselRef.current;
        if (direction === 'left') {
            carousel.scrollBy({
                left: -carousel.offsetWidth,
                behavior: 'smooth',
            });
        } else if (direction === 'right') {
            carousel.scrollBy({
                left: carousel.offsetWidth,
                behavior: 'smooth',
            });
        }
    };

    return (
        <>

            <div className=" flex justify-center items-center relative">

                {/* Left navigation buttons */}
                <button
                    className="absolute  top-1/2 -left-8 transform -translate-y-1/2  text-white rounded-full text-xl w-1/3 z-30  "
                    onClick={() => moveCarousel('left')}
                >

                    <img className='w-16' src={leftArrowVector} alt="left arrow carousel" />
                </button>

                <div className='flex flex-col w-full '>
                    <p className='font-ClashGrotesk font-medium text-sm  lg:text-xl w-full text-[var(--white)] bg-[var(--medium-purple)] p-6 pb-20 pl-10  relative top-14 rounded-[2.5rem]'>Recent Net Gala Events</p>
                    <div className="carousel  w-full z-20" ref={carouselRef}>

                        <div className="carousel-item w-full  ">
                            <img
                                src={footballImage}
                                className="w-full "
                                alt="Tailwind CSS Carousel component"
                            />
                        </div>
                        <div className="carousel-item w-full">
                            <img
                                src={footballImage}
                                className="w-full"
                                alt="Tailwind CSS Carousel component"
                            />
                        </div>
                        <div className="carousel-item w-full">
                            <img
                                src={footballImage}
                                className="w-full"
                                alt="Tailwind CSS Carousel component"
                            />
                        </div>

                    </div>
                    <p className='font-manrope text-base lg:text-3xl font-semibold w-full  text-[var(--medium-purple)] bg-[var(--white)] p-6 pt-20 pl-10 relative bottom-14  rounded-[2.5rem]'>Delhi Football Tournament</p>
                </div>
                {/* Right Navigation button */}
                <button
                    className="absolute  top-1/2 -right-8   transform -translate-y-1/2  text-white rounded-full text-xl   z-30"
                    onClick={() => moveCarousel('right')}
                >

                    <img className='w-16' src={rightArrowVector} alt="right arrow carousel" />
                </button>

            </div>


        </>
    )
}

export default StayInTouchCarousel
