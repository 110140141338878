import React, { useState } from 'react';
import logo from '../../Asset/logo.svg';
import logoOther from '../../Asset/logo-other-pages.svg';
import { Link, useLocation } from 'react-router-dom';
import hamburgerWhite from '../../Asset/Header/hamburger-white.svg';
import hamburgerPurple from '../../Asset/Header/hamburger-purple.svg';
import NavigationMobile from './NavigationMobile'; // Import mobile navigation
import './header.css'
import { useParams } from 'react-router-dom';


const Header = () => {
  const location = useLocation();
  const isHomepage = location.pathname === "/";
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  let { eventId } = useParams();
  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const closeMenu = () => {
    setIsMenuOpen(false);
  };

  return (
    <header className={`h-auto absolute top-0 md:top-0 left-0  w-full flex items-center justify-start px-4 py-2 z-40 `}>
      {/* Logo */}
      <div className="logo w-1/2  md:ml-10 ">
        <Link to="/">
          <img
            src={isHomepage ? logo : logoOther}
            alt="Net Gala Logo"
            className="w-32"
          />
        </Link>
      </div>

      {/* Navigation Links for md devices */}
      <div className='w-[45%] flex justify-end  '>

      <nav
         className={`hidden lg:flex items-center  justify-between w-[37%] px-5 py-3 mr-8 font-ClashGrotesk text-lg lg:text-xl  rounded-2xl  backdrop:blur-xl backdrop-opacity-80 bg-opacity-85 ${isHomepage ? 'bg-[#40404070]  border border-solid border-[var(--dull-white-homepage-nav)] font-[480] ' : 'bg-[#FFFFFB33] border border-solid border-[var(--dull-white-otherpage-nav)] font-medium'}`}
      >
        {/* <Link to={`/events/${eventId}`}> */}
        <Link to={`/events/0e26fbc7-be70-4a66-bebd-f3638e503df4`}>
          <button
            className={`nav-button px-2 ${isHomepage ? 'text-white' : 'text-[var(--dark-blue)]'}`}
          >
            Events
          </button>
        </Link>
       

        <Link to="/about">
          <button
            className={`nav-button px-2 ${isHomepage ? 'text-white' : 'text-[var(--dark-blue)]'}`}
          >
            About
          </button>
        </Link>
      </nav>

      </div>
      

      {/* Hamburger Menu for mobile */}
      <nav className="lg:hidden z-30">
        <button className="text-white" onClick={toggleMenu}>
          <img
            src={isHomepage ? hamburgerWhite : hamburgerPurple}
            alt="Menu Icon"
            className="w-8"
          />
        </button>
      </nav>

      {/* Mobile Navigation */}
      <NavigationMobile isOpen={isMenuOpen} onClose={closeMenu} />
    </header>
  );
};

export default Header;
