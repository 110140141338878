import React from 'react'
import './brandAndCommunity.css'
import NykaaBrand from "../../../Asset/nykaa-brand.svg"
import KicaBrand from "../../../Asset/kica-brand.svg"
import GBrand from "../../../Asset/G-brand.svg"
import DlfBrand from "../../../Asset/dlf-brand.svg"
import BudweiserBrand from "../../../Asset/budweiser-brand.svg"

const brand = () => {
  return (

    // Brands Go here
    <div class="w-full brand-main flex flex-col flex-wrap overflow-hidden pattern-container">

      <div className="heading-brand flex flex-col flex-wrap pt-10 md:pt-20 2xl:pt-28">
        <p class="text-[#E0E0E0] text-4xl md:text-[3.5rem]  text-left p-4 pl-6 md:pl-8 2xl:pl-12 font-ClashGrotesk font-[470] mb-4">Brands we have teamed up with</p>

        {/* All brand images here */}
        <div className="carousel  w-[100vw] ">
          <div className="carousel-item  w-[19vw] ">
            <img className='w-full'
              src={NykaaBrand}
              alt="Nykaa logo" />
          </div>
          <div className="carousel-item w-[19vw]">
            <img className='w-full'
              src={DlfBrand}
              alt="Dlf Brand logo" />
          </div>
          <div className="carousel-item w-[19vw]">
            <img className='w-full'
              src={BudweiserBrand}
              alt="Budweiser brand logo" />
          </div>
          <div className="carousel-item w-[19vw] ">
            <img className='w-full'
              src={KicaBrand}
              alt="Kica logo" />
          </div>
          <div className="carousel-item  w-[19vw]">
            <img className='w-full'
              src={GBrand}
              alt="G Brand logo" />
          </div>

          {/* Repeating  brands to see scroll effect */}
          <div className="carousel-item  w-[19vw] ">
            <img className='w-full'
              src={NykaaBrand}
              alt="Nykaa logo" />
          </div>
          <div className="carousel-item w-[19vw]">
            <img className='w-full'
              src={DlfBrand}
              alt="Dlf Brand logo" />
          </div>
          <div className="carousel-item w-[19vw]">
            <img className='w-full'
              src={BudweiserBrand}
              alt="Budweiser brand logo" />
          </div>
          <div className="carousel-item w-[19vw] ">
            <img className='w-full'
              src={KicaBrand}
              alt="Kica logo" />
          </div>
          <div className="carousel-item w-[19vw] ">
            <img className='w-full'
              src={GBrand}
              alt="G Brand logo" />
          </div>
        </div>
        {/* Repeating brands end here */}


      </div>



    </div>
    
  )
}

export default brand
