import React, {useState, useEffect} from 'react'
import rightArrowVector from "../../../Asset/right-arrow-vector.svg"
import paddelBall from "../../../Asset/padel-ball.svg"
import paddelBallMobile from "../../../Asset/padel-ball-mobile.svg"
import leagueBadge from "../../../Asset/Events/League-Badge/leagueBadge.svg"
import leagueBadgeTournament from "../../../Asset/Events/League-Badge/league-badge-tournament.svg"
import calendarVector from "../../../Asset/Events/calendar-vector-white.svg"
import timeVector from "../../../Asset/Events/time-vector-white.svg"
import locationVector from "../../../Asset/Events/location-vector-white.svg"
import coinVector from "../../../Asset/Events/coin-vector-white.svg"
import { useParams } from 'react-router-dom'
import { Link } from 'react-router-dom'
import { format } from "date-fns";
import Axios from "axios";

const UpcomingEvents = () => {

    let { eventId } = useParams();

    const [eventData, setEventData] = useState([]);
    const [loading, setLoading] = useState(true); // Track loading state
    const [error, setError] = useState(null); // Track error state
    const [formattedDates, setFormattedDates] = useState({});

    useEffect(() => {
        const fetchData = async () => {
          try {
            const res = await Axios.get(`${process.env.REACT_APP_BASE_URL}/events/`);
            
            
            setEventData(res.data); // Set event data from response
            setLoading(false); // Stop loading
    
            const data = res.data[0];
    
            // Extract and format all relevant dates
            if (data.event_schedule) {
              const startDate = format(new Date(data.event_schedule.StartDate), "do MMM, EEEE, yyyy");
              const endDate = format(new Date(data.event_schedule.EndDate), "do MMM, EEEE, yyyy");
              const registrationStartDate = format(new Date(data.event_schedule.RegistrationStartDate), "dd MMM, EEEE yyyy");
              const registrationEndDate = format(new Date(data.event_schedule.RegistrationEndDate), "dd MMM, EEEE yyyy");
              const StartTimeStamp = format(new Date(data.event_schedule.StartTimeStamp), "hh:mm a");
              const EndTimeStamp = format(new Date(data.event_schedule.EndTimeStamp), "hh:mm a");
    
    
              // Store formatted dates in state
              setFormattedDates({
                startDate,
                endDate,
                registrationStartDate,
                registrationEndDate,
                StartTimeStamp,
                EndTimeStamp
              });
            }
    
          } catch (error) {
            setError(error.message);
            setLoading(false);
          }
        };
    
        fetchData();
      }, []);
    
    return (
        <>
            <div className='w-full flex flex-col gap-2 mt-10'>
                {/* All Upcoming Events Text Here */}
                <div className='flex justify-end'>
                    {/* <span className='flex gap-2 text-2xl  font-manrope font-semibold text-[var(--white)] opacity-[50%] hover:opacity-70 cursor-pointer'>All Upcoming Events <img src={rightArrowVector} alt="right arrow vector" /></span> */}
                </div>

                <div className="homeAboutUs-event-card mb-12 flex flex-col md:flex-row justify-between items-center w-full p-2 rounded-3xl shadow-xl mx-auto">
                    {/* Inside container left part goes here */}
                    <div className="flex flex-col md:flex-row xl:w-[57%] md:w-[60%] md:h-[95%]  items-center md:p-4 p-2 md:gap-4 lg:gap-6 ">
                        {/* Image here */}
                        <div className="md:hidden ">
                            <img className='' src={paddelBallMobile} alt="Running " />
                        </div>
                        <div className="hidden md:block p-6 w-[90%] h-[90%] ">
                            <img className='w-full h-full' src={paddelBall} alt="Yoga " />
                        </div>

                        {/* Text part here */}
                        <div className="flex flex-col justify-start  md:gap-2 md:w-full text-[var(--white)]  ">
                            <div className="flex ">
                                {/* Heading of the card */}
                                <div className="w-3/5 md:w-full">
                                    <h1 className="xl:text-5xl lg:text-3xl md:text-2xl text-xl font-[470] py-2 text-left font-ClashGrotesk">
                                        Women’s Padel Tournament 
                                    </h1>
                                </div>

                                {/* Badge image for Mobile view */}
                                <div className="md:hidden w-[45%] ml-0 ">
                                    <img
                                        className="more-events-leagueBadge-image -mt-16"
                                        src={leagueBadge}
                                        alt="League Badge Smiley"
                                    />
                                </div>
                            </div>

                            <p className="pb-3 text-left font-manrope lg:text-lg text-sm ">Perfect for Beginners. Focus on self-paced improvement with access to fundamental resources and community support.</p>
                            <hr className="border border-[var(--medium-purple)] border-solid md:mb-2 mb-4 md:w-[95%]" />

                            {/* Inside partition */}
                            <div className="flex pb-1 gap-2 w-full font-manrope text-sm  lg:text-base ">
                                <span className="flex justify-start items-center gap-2 w-full ">
                                    <img src={calendarVector} alt="calendar vector" className='w-[1.1rem]' /> 28th July, 2024
                                </span>
                                <span className="flex justify-start items-center gap-2 w-full">
                                    <img src={timeVector} alt="clock vector" className='w-[1.1rem]' /> 6:30 AM - 9:30 AM
                                </span>
                            </div>
                            <div className="flex pb-2 gap-2 w-full font-manrope text-sm  lg:text-base">
                                <span className="flex justify-start items-center gap-2 w-full">
                                    <img src={locationVector} alt="location vector" className='w-[1.1rem]' /> Aravalli Hills
                                </span>
                                <span className="flex justify-start items-center gap-2 w-full">
                                    <img src={coinVector} alt="coin vector" className='w-[1.1rem]' /> ₹1000
                                </span>
                            </div>
                            <hr className="border border-[var(--medium-purple)] border-solid md:mt-2 mt-4 md:w-[95%]" />
                        </div>
                    </div>

                    {/* Inside container right part goes here */}
                    <div className="flex flex-col justify-between md:items-end items-center md:w-[30%] md:h-[100%] gap-3  md:mr-5 ">
                        {/* Badge Image here */}
                        <img 
                            className="more-events-leagueBadge-image hidden md:block -mt-16 w-[20vw]"
                            src={leagueBadgeTournament}
                            alt="League Badge Smiley"
                        />

                        {/* Register Button here */}
                        <Link to={`/events/0e26fbc7-be70-4a66-bebd-f3638e503df4`} className=' mr-12'>
                            <button className=" flex justify-center border border-solid bg-[var(--button-green)] border-[var(--medium-purple)] text-[var(--purple-blue)] text-lg lg:text-xl xl:text-2xl md:px-32 md:py-6 px-28 md:w-full  py-3 rounded-xl md:mr-4 font-manrope font-bold ">
                                Register
                            </button>
                        </Link>
                    </div>
                </div>

            </div>
        </>
    )
}

export default UpcomingEvents
