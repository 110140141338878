import React from 'react';
import './footer.css';
import logo from '../../Asset/footer-logo.svg';
import { Link, useLocation } from 'react-router-dom';
import webVersion from "../../../package.json"

const Footer = () => {

  const location = useLocation();
  const isHomepage = location.pathname === "/";

  return (

    <footer className={`footer  pb-8 ${isHomepage ? 'footer-home-bg-color pattern-container' : ''} w-full flex flex-col p-6`} >
      <div className="footer-content  rounded-3xl z-50 py-5">
        <div className="footer-sub-content text-[var(--white)] flex flex-col md:flex-row w-full p-4 md:p-12 xl:p-16 md:pt-20">
          <div className="footer-left flex flex-col gap-6 md:gap-10 md:w-1/2  md:items-start justify-center items-center">
            <Link to="/">
              <img src={logo} alt="Net Gala Logo" className="footer-logo" /></Link>

            <p className='font-manrope font-[460] md:text-xl text-base'>We take our fun very seriously. <br /> This will be a community like no other.</p>
            <button className="footer-button font-manrope md:text-base font-semibold  text-xl bg-[var(--button-green)] text-[var(--dark-blue)] md:w-[60%] px-12 py-3 rounded-lg">Tell us more about yourself!</button>
          </div>
          <div className="footer-right footer-links font-[460] font-ClashGrotesk md:w-[40%] flex justify-between items-center  w-full p-6 text-base md:text-xl ">

            {/* for mobile device and medium devices both */}
            <div className='flex flex-col md:flex-row gap-3 justify-between md:hidden  w-1/2'>
              <ul className='flex flex-col gap-3'>
                <li><Link to="/events">Events</Link></li>

                <li><Link to="/privacy">Privacy</Link></li>

              </ul>
              <ul className='flex flex-col gap-3'>
                <li><Link to="/about">About</Link> </li>

                <li><Link to="/terms">Terms</Link></li>
              </ul>
            </div>


            <ul className='md:flex flex-col gap-3 hidden '>
              <li><Link to="/events">Events</Link></li>

              <li><Link to="/privacy">Privacy</Link></li>

            </ul>
            <ul className='md:flex flex-col gap-3 hidden'>
              <li><Link to="/about">About</Link> </li>

              <li><Link to="/terms">Terms</Link></li>
            </ul>
            <ul className='flex flex-col gap-3 w-1/2 md:w-auto'>
              <li>Instagram &#8599;</li>
              <li>Email &#8599;</li>

            </ul>
          </div>
        </div>
      </div>

      <div className={`footer-bottom flex flex-col gap-2 md:flex-row w-full justify-center items-center md:gap-16 font-manrope p-2 text-base md:text-xl`} style={{ color: isHomepage ? 'var(--white)' : 'var(--base-color)' }}>

        <p className='font-bold'>Version: {webVersion.version}</p>
      </div>
    </footer>

  );
};

export default Footer;